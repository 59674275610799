import React from 'react'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { default as Layout } from 'layouts/Main'
import SEO from 'components/SEO'
import ListIcons from 'components/ListIcons'
import Sector from 'components/Sector'
import FeedbackForm from 'components/FeedbackForm'
import Map from 'components/Map'
import FeedbackButton from 'components/FeedbackButton'
import Banner from 'components/Banner'
import Secondary from 'components/Secondary'

const Contacts = () => (
	<Layout>
		<SEO
			title="Контакты — Парсинг сайтов «Парсик»"
			description="Белгородская обл., г. Старый Оскол, Коммунистическая ул., д.12, к.1, оф.274, info@parsic.ru, +7 (999) 700-22-01"
		/>
		<Banner
			title="Контакты"
			description="Звоните, пишите. Ответим на любые вопросы"
			image={{ name: 'home', width: 506, height: 392 }}
		>
			<FeedbackButton theme="light" size="lg">
				Связаться с нами
			</FeedbackButton>
		</Banner>
		<Sector>
			<Grid fluid>
				<Row>
					<Col md={7} className="mb-lg">
						<h2 className="h3 mb">Свяжитесь с нами</h2>
						{/* prettier-ignore */}
						<ListIcons
							items={[
								{icon: 'phone', text: <a className="text-nowrap" href="tel:+79997002201" title="Позвонить">+7 (999) 700-22-01</a>},
								{icon: 'mail', text: <a className="text-nowrap" href="mailto:info@parsic.ru" title="Написать на почту">info@parsic.ru</a>},
								{icon: 'telegram', text: <a className="text-nowrap" href="https://telegram.me/iparsic" title="Написать в Telegram" target="_blank" rel="nofollow noopener noreferrer">Telegram</a>},
								{icon: 'whatsappEmpty', text: <a className="text-nowrap" href="https://api.whatsapp.com/send?phone=79997002201" title="Написать в WhatsApp" target="_blank" rel="nofollow noopener noreferrer">WhatsApp</a>},
								{icon: 'vk', text: <a className="text-nowrap" href="https://vk.com/parsic_ru" title="Написать в ВКонтакте" target="_blank" rel="nofollow noopener noreferrer">ВКонтакте</a>},
								{icon: 'location', text: <>Почтовый адрес: 309500, Белгородская&nbsp;обл., г.&nbsp;Старый&nbsp;Оскол, Королева&nbsp;мкр., д.&nbsp;31, кв.&nbsp;44</>},
								{icon: 'location', text: <>Адрес офиса: <a href="https://maps.app.goo.gl/bGEu6muNEV3To3FX7" title="Посмотреть на карте" target="_blank" rel="nofollow noopener noreferrer">Белгородская обл., г.&nbsp;Старый&nbsp;Оскол, Коммунистическая&nbsp;ул., д.&nbsp;12, к.&nbsp;1, №&nbsp;274</a></>},
								{icon: 'clock', text: 'пн-пт: 09:00 - 18:00, сб, вс: выходной'},
								{icon: 'user', text: <>Елисеев Дмитрий Геннадиевич, ИНН: 312824663029<br/><Secondary>Техническая разработка и сопровождение проекта</Secondary></>},
								{icon: 'user', text: <>Елисеева Наталья Николаевна, ИНН: 312806709760<br/><Secondary>Консультация, работа с клиентами и документами</Secondary></>},
							]}
						/>
					</Col>
					<Col md={5}>
						<FeedbackForm
							url="contact"
							title={<h2 className="h3 mb">Напишите нам</h2>}
							success="Спасибо! Мы свяжемся с вами в ближайшее время"
							fields={{
								name: { required: true },
								phone: { required: false },
								email: { required: true },
								message: { required: true },
							}}
							isInline
						/>
					</Col>
				</Row>
			</Grid>
		</Sector>
		<Map src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2494.668590932482!2d37.8386227!3d51.2988226!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x412509afd37ccea1%3A0xec93294918052adc!2z0JrQvtC80LzRg9C90LjRgdGC0LjRh9C10YHQutCw0Y8g0YPQuy4sIDEy0LoxLCDQodGC0LDRgNGL0Lkg0J7RgdC60L7Quywg0JHQtdC70LPQvtGA0L7QtNGB0LrQsNGPINC-0LHQuy4sIDMwOTUxNA!5e0!3m2!1sru!2sru!4v1711036335368!5m2!1sru!2sru" />
	</Layout>
)

export default Contacts
