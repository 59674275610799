import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './ListIcons.module.scss'
import Icon from 'components/Icon'

const b = bem.listIcons(css)

const ListIcons = ({ items, size }) => {
	return (
		<ul className={b({ size })}>
			{items.map(({ icon, text }, index) => (
				<li className={b('item')} key={index}>
					<div className={b('icon')}>
						<Icon type={icon} />
					</div>
					<div>{text}</div>
				</li>
			))}
		</ul>
	)
}

ListIcons.propTypes = {
	size: PropTypes.oneOf(['lg']),
	items: PropTypes.arrayOf(
		PropTypes.shape({
			icon: PropTypes.string.isRequired,
			text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
		}).isRequired
	).isRequired,
}

export default ListIcons
