import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Map.module.scss'

const b = bem.map(css)

const Map = ({ src, height, isAdaptive }) => (
	<div className={b({ 'is-adaptive': isAdaptive })} style={{ height }}>
		<iframe title="Карта" src={src} allowFullScreen />
	</div>
)

Map.defaultProps = {
	height: 400,
}

Map.propTypes = {
	src: PropTypes.string.isRequired,
	height: PropTypes.number,
	isAdaptive: PropTypes.bool,
}

export default Map
